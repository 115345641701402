import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PolicyPageQuery } from '../types/graphql-types';
import Ctx from '../types/page-context';
import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import ThemeMdx from "../containers/ThemeMdx";

const WrapperProps = {
  pt: {
    _: 56,
    md: 64,
    lg: 192,
  },
  pb: {
    _: 56,
    md: 64,
    lg: 96,
  },
};
const ContainerProps = {
  textAlign: 'left'
};

type Props = PageProps<PolicyPageQuery, Ctx>;
const PolicyTemplate: React.FC<Props> = ({data, pageContext, location}) => {
  const {iubendaPolicyType} = pageContext;
  const policy = (
    <ThemeMdx title={data.info.frontmatter.title} data={data} location={location.pathname} pageContext={pageContext} >
      <Box {...WrapperProps}>
        <Container {...ContainerProps}>
          <div 
            dangerouslySetInnerHTML={{ __html: data.iubenda[iubendaPolicyType] }} 
          />
        </Container>
      </Box>
    </ThemeMdx>
  );

  return policy;
};

export default PolicyTemplate;

export const pageQuery = graphql`
  query PolicyPage($id: String!, $idFrontmatter: String!, $lang: String!, $iubendaDocumentId: String!) {
    info: mdx(id: { eq: $id }) {
      ...PolicyPageFragment
    }
    otherLanguages: allMdx(filter: {frontmatter: {id: {eq: $idFrontmatter}, lang: {ne: $lang}}}) {
      edges {
        node {
          frontmatter {
            lang
            slug
          }
        }
      }
    }
    iubenda: iubendaDocument(documentId: {eq: $iubendaDocumentId}) {
      id
      documentId
      cookiePolicy
      privacyPolicy
    }
  }
`;
/*
export const pageQuery = graphql`
  query PolicyPage($id: String!, $idFrontmatter: String!, $lang: String!) {
    info: mdx(id: { eq: $id }) {
      ...Policy
    }
    iubenda: allIubendaDocument {
      nodes {
        id
        documentId
        cookiePolicy
        privacyPolicy
      }
    }
  }
`;
*/

export const policyFragment = graphql`
  fragment PolicyPageFragment on Mdx {
    frontmatter {
      id
      title
      templateKey
      description
      slug
      iubendaDocumentId
    }
  }
`;